import React, { useRef, useEffect } from 'react';

import { realtimedb } from './App';
import { ref, set, onValue } from "firebase/database";


const Cueball = ({ size, x, y, thisRef, tableId }) => {
    const circleCanvasRef = useRef(null);
    const circleCanvasWrapperRef = useRef(null);
    const circleCtxRef = useRef(null);
    const reddotRef = useRef({ x: 100, y: 100, opacity: 1 });
    const cueballRef = useRef({ x: 0, y: 0 });
    const db = realtimedb;

    useEffect(() => {
        const circleCanvas = circleCanvasRef.current;
        const whiteCircleRadius = circleCanvas.width / 2 * 0.9;
        const reddotRadius = whiteCircleRadius / 6;
        const circleCanvasWrapper = circleCanvasWrapperRef.current;
        const circleCtx = circleCanvas.getContext('2d');
        circleCtxRef.current = circleCtx;

        const reddot = reddotRef.current;
        const cueball = cueballRef.current;

        circleCanvasWrapper.style.position = 'absolute';
        circleCanvasWrapper.style.left = `${cueball.x}px`;
        circleCanvasWrapper.style.top = `${cueball.y}px`;
        circleCanvasWrapper.style.zIndex = `4000`;

        let animationStarted = false;


        const handleClear = () => {
            //hide the wrapper
            circleCanvasWrapper.style.display = 'none';
            thisRef.current.hidden = true;
            set(ref(db, `cueballs/${tableId}/hidden`), thisRef.current.hidden);
        };
        thisRef.current.handleClear = handleClear;

        // this line needs to be on overlay since we better let overlay start without a cueball
        handleClear();

        const handleShow = () => {
            //show the wrapper
            circleCanvasWrapper.style.display = 'block';
            thisRef.current.hidden = false;
            set(ref(db, `cueballs/${tableId}/hidden`), thisRef.current.hidden);
        };
        thisRef.current.handleShow = handleShow;

        const handleToggle = () => {
            if (thisRef.current.hidden) {
                handleShow();
            }
            else {
                handleClear();
            }
        }
        thisRef.current.handleToggle = handleToggle;

        const handleMouseDown = (event) => {
            const rect = circleCanvasWrapper.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            event.preventDefault();

            if (event.detail === 1) {
                // Single-click to mark the reddot
                reddot.opacity = 0.8;
                reddot.x = x;
                reddot.y = y;

                set(ref(db, `cueballs/${tableId}/reddot`), reddot);


            } else if (event.detail > 1) {
                // Click-and-hold to drag the canvas
                console.log("mousedownevent: ", event.clientX, event.clientY, x, y, reddot.x, reddot.y);

                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);
            }
        };

        const handleMouseMove = (event) => {
            console.log(event.movementX, event.movementY);
            cueball.x = cueball.x + event.movementX;
            cueball.y = cueball.y + event.movementY;
            circleCanvasWrapper.style.left = `${cueball.x}px`;
            circleCanvasWrapper.style.top = `${cueball.y}px`;

            set(ref(db, `cueballs/${tableId}/cueball`), cueball);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);

            set(ref(db, `cueballs/${tableId}/cueball`), cueball);
        };

        const fadeOutCircle = (reddot) => {
            if (reddot.opacity < 0) {
                animationStarted = false;
                return;
            }

            // Clear the circle canvas
            circleCtx.clearRect(0, 0, circleCanvas.width, circleCanvas.height);

            // Draw the filled white circle with the reduced opacity
            circleCtx.fillStyle = `rgba(255, 255, 255, ${reddot.opacity})`;
            circleCtx.strokeStyle = `rgba(0, 0, 0, ${reddot.opacity})`;
            circleCtx.lineWidth = 2;
            circleCtx.beginPath();
            circleCtx.arc(circleCanvas.width / 2, circleCanvas.height / 2, whiteCircleRadius, 0, 2 * Math.PI);
            circleCtx.fill();
            circleCtx.stroke();

            requestAnimationFrame(() => fadeOutCircle(reddot));
        };

        const fadeOutRedDot = (reddot) => {
            if (reddot.opacity <= 0) {
                animationStarted = false;
                return;
            }

            // Draw the red dot with the reduced opacity
            circleCtx.fillStyle = `rgba(255, 0, 0, ${reddot.opacity})`;
            circleCtx.beginPath();
            circleCtx.arc(reddot.x, reddot.y, reddotRadius, 0, 2 * Math.PI);
            circleCtx.fill();

            requestAnimationFrame(() => fadeOutRedDot(reddot));
        };

        circleCanvasWrapper.addEventListener('mousedown', handleMouseDown);

        // listen to realtime database change and update reddot cueball and hidden
        const reddotFBRef = ref(db, `cueballs/${tableId}/reddot`);
        const cueballFBRef = ref(db, `cueballs/${tableId}/cueball`);
        const hiddenFBRef = ref(db, `cueballs/${tableId}/hidden`);

        onValue(reddotFBRef, (snapshot) => {
            const reddotFB = snapshot.val();
            if (reddotFB) {
                reddot.x = reddotFB.x;
                reddot.y = reddotFB.y;
                reddot.opacity = reddotFB.opacity;
            }
            else {
                set(ref(db, `cueballs/${tableId}/reddot`), reddot);
            }
        });

        onValue(cueballFBRef, (snapshot) => {
            const cueballFB = snapshot.val();
            if (cueballFB) {
                cueball.x = cueballFB.x;
                cueball.y = cueballFB.y;

                circleCanvasWrapper.style.left = `${cueball.x}px`;
                circleCanvasWrapper.style.top = `${cueball.y}px`;
            }
            else {
                set(ref(db, `cueballs/${tableId}/cueball`), cueball);
            }
        });

        onValue(hiddenFBRef, (snapshot) => {
            const hiddenFB = snapshot.val();
            if (hiddenFB !== null) {
                thisRef.current.hidden = hiddenFB;
                if (thisRef.current.hidden) {
                    handleClear();
                } else {
                    handleShow();
                }
            }
            else {
                set(ref(db, `cueballs/${tableId}/hidden`), true);
            }
        });

        if (!animationStarted) {
            animationStarted = true;
            requestAnimationFrame(() => fadeOutCircle(reddot));
            requestAnimationFrame(() => fadeOutRedDot(reddot));
        }

        return () => {
            circleCanvasWrapper.removeEventListener('mousedown', handleMouseDown);
        };
    }, [size, thisRef, tableId]);

    return (
        <div ref={circleCanvasWrapperRef}>
            <canvas ref={circleCanvasRef} width={size} height={size} />
        </div>
    );
};

export default Cueball;