import React, { useState, useEffect } from 'react';
import { db } from './App';
import { getDoc, doc, onSnapshot } from "firebase/firestore"; 


const Component = ({ scale=1.0, tableName, fieldName, initialData, position, color="white", stroke="black"}) => {
  const [data, setData] = useState(initialData);
  const fontSize = 18 * scale;
  const centeredFieldName = ["hostRace", "hostScore", "guestScore"];
  const widthFieldName = {
    "hostRace": "200px",
    "hostScore": "32px",
    "guestScore": "32px"
  }

  useEffect(() => {
    let unsubscribeMatch; // To hold the match listener unsubscribe function
    let unsubscribeTable; // To hold the table listener unsubscribe function
    let matchId;

    // Fetch data from Firestore and set up the listener
    const fetchData = async () => {
      let tableRef = doc(db, "tables", tableName);
      let matchRef;

      // if tableRef doc changed, update matchRef
      unsubscribeTable = onSnapshot(tableRef, async (tableDoc) => {
        // if matchId became undefined, unsubscribe from matchRef
        if (tableDoc.data().matchId === undefined && unsubscribeMatch) {
            setData(initialData);
            unsubscribeMatch();
        } else if (tableDoc.data().matchId) {
            matchId = tableDoc.data().matchId;
            console.log("matchId: " + matchId);
            matchRef = await doc(db, "matches", tableDoc.data().matchId);

            unsubscribeMatch = onSnapshot(matchRef, async (fdoc) => {
                let data = fdoc.data()[fieldName];
                // upper case if fieldname are host or guest
                if (data && (fieldName === "host" || fieldName === "guest")) {
                  if (!(data.includes("/"))) {
                    let userDocRef = await doc(db, "users", data);
                    const userDocSnapshot = await getDoc(userDocRef);
                    if (userDocSnapshot.exists()) {
                      data = userDocSnapshot.data().displayName;
                    }

                  }
                  data = data.toUpperCase();
                }
                else if (data && fieldName === "hostRace") {
                  if (fdoc.data()["hostRace"] !== fdoc.data()["guestRace"]) {
                    data = `Race to ${fdoc.data()["hostRace"]}/${fdoc.data()["guestRace"]}`
                  }
                  else {
                    data = `Race to ${fdoc.data()["hostRace"]}`;
                  }
                }

                if (data !== null){
                  setData(data);
                }
            });
        } else {
          setData(initialData);
        }
      });

    };

    if (tableName && fieldName){
      fetchData();
    }

    // Return a cleanup function to unsubscribe from Firestore listeners to prevent memory leaks
    return () => {
      if (unsubscribeMatch) {
        unsubscribeMatch();
      }
      if (unsubscribeTable) {
        unsubscribeTable();
      }
    };
  }, [tableName, fieldName, initialData]);

  return (
    <div className="component" style={{ fontSize: fontSize.toString() + "px", color: color, position: 'absolute', top: position.top, left: position.left, fontWeight: 'bold', WebkitTextStroke: stroke }}>
      <p style={{ textAlign: centeredFieldName.includes(fieldName) ? "center" : "left", width: widthFieldName.hasOwnProperty(fieldName) ? widthFieldName[fieldName] : "auto" }}>{data}</p>
    </div>
  );
};

export default Component;
