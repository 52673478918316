
const BackgroundComponent = ({ position, image}) => {
    
  return (
    <div className="background-component" style={{ position: 'absolute', top: position.top, left: position.left }}>
        <img alt="scoreboard background" src={image} width={position.width}></img>
    </div>
  );
};

export default BackgroundComponent;

