import Component from './Component';
import BackgroundComponent from './BackgroundComponent';

import eloscoreboard from './assets/elo-scoreboard.png';
import React from 'react';

const Scoreboard = ({ position, tableName, scale=1.0}) => {

  React.useEffect(() => {
  }, [tableName]);

  return (
    <div className='overlay-content'>
      <BackgroundComponent position={{ top: position.top + 0 * scale, left: position.left + 0 * scale, width: 450 * scale }} image={eloscoreboard} />
      <Component scale={scale} tableName={tableName} fieldName="description"  initialData={"Table " + tableName.split("_")[1] } position={{ top: position.top - 24 * scale, left: position.left + 45 * scale}} color={'#CCC'} />
      <Component scale={scale} tableName={tableName} fieldName="host" initialData={"EvoSports Venue: " + tableName.split("_")[0] } position={{ top: position.top + 14 * scale, left: position.left + 100 * scale }} />
      <Component scale={scale * 1.2} tableName={tableName} fieldName="hostScore" initialData={0} position={{ top: position.top + 11 * scale, left: position.left + 386 * scale }} />
      <Component scale={scale} tableName={tableName} fieldName="guest"  initialData={"Table " + tableName.split("_")[1] } position={{ top: position.top + 50 * scale, left: position.left + 100 * scale }} />
      <Component scale={scale * 1.2} tableName={tableName} fieldName="guestScore"  initialData={0} position={{ top: position.top + 47 * scale, left: position.left + 386 * scale }} />
      <Component scale={scale * 0.9} tableName={tableName} fieldName="hostRace"  initialData="Race to 0" position={{ top: position.top + 81 * scale, left: position.left + 285 * scale }} />
      {/* Add other components as needed */}
    </div>
  );
};

export default Scoreboard;

