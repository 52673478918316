import React, { useRef, useEffect } from 'react';
import { db, realtimedb } from './App';
import { ref, set, onValue } from "firebase/database";
import './Canvas.css';

const Canvas = ({ tableId = "colin_1" }) => {
    const canvasRef = useRef(null);
    let traces = [];

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = 1920;
        canvas.height = 1080;

        // Variable to store the current trace
        let currentTrace = null;
        let number = 0;
        let fpsSkipped = 3;
        let fadeoutVelocity = 0.0008;
        let isDesktop = true;
        let currentColor = 0;
        let colors = ["25, 144, 100", "5, 81, 174", "255, 2, 2", "255, 248, 250"];
        let colorNum = colors.length;

        // Subscribe to database canvas update
        const dbRef = ref(realtimedb, `canvas/${tableId}/traces`);
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                traces = data; // This will throw an error since traces is a const array
            }
        });

        function getColor() {
            var pickedColor = colors[currentColor];
            currentColor++;
            currentColor = currentColor % colorNum;

            return pickedColor;
        }

        function handleClear(event) {
            // remove all items in traces
            traces.splice(0, traces.length);
        }

        // Function to handle touch or pointer down event
        function handleDown(event) {
            // fix bug that click and touch will double fire
            if (currentTrace) {
                return;
            }

            if (event.pointerType === "mouse") {
                isDesktop = true;
            } else if (event.pointerType === "touch") {
                isDesktop = false;
            }

            // Prevent scrolling on touch devices
            event.preventDefault();

            // Start a new trace
            currentTrace = {
                color: getColor(),
                points: [],
                opacity: 1
            };

            // Add the current position to the trace
            const { offsetX, offsetY } = event.touches ? event.touches[0] : event;
            console.log(event);
            currentTrace.points.push({ x: offsetX, y: offsetY });

            // Add the trace to the traces array
            traces.push(currentTrace);

            // Add event listeners for touch or pointer move and up events
            //canvas.addEventListener('pointermove', handleMove);
            //canvas.addEventListener('touchmove', handleMove);
            //canvas.addEventListener('pointerup', handleUp);
            //canvas.addEventListener('touchend', handleUp);
        }

        // Function to handle touch or pointer move event
        function handleMove(event) {
            // Prevent scrolling on touch devices
            event.preventDefault();

            // Get the current position
            const { offsetX, offsetY } = event.touches ? event.touches[0] : event;

            // Check if the current position is outside the canvas area
            if (offsetX < 0 || offsetX > canvas.width || offsetY < 0 || offsetY > canvas.height) {
                // Trigger handleUp function
                handleUp();
                return;
            }

            // mobile doesn't need the latency logic to strengthen the line
            if (!isDesktop) {
                // Add the current position to the current trace
                currentTrace.points.push({ x: offsetX, y: offsetY });
            } else if (isDesktop && number % fpsSkipped === 0) {
                // put latency intentionally for desktop to straighten the trace
                // Add the current position to the current trace
                currentTrace.points.push({ x: offsetX, y: offsetY });
            }

            number = number % fpsSkipped + 1;
        }

        // Function to handle touch or pointer up event
        function handleUp() {
            // Remove the event listeners for touch or pointer move and up events
            canvas.removeEventListener('pointermove', handleMove);
            canvas.removeEventListener('touchmove', handleMove);
            canvas.removeEventListener('pointerup', handleUp);
            canvas.removeEventListener('touchend', handleUp);

            // Reset the current trace
            currentTrace = null;

            // push the traces to facebase realtime database
            set(ref(realtimedb, `canvas/${tableId}/traces`), traces);
        }

        // Add event listeners for touch or pointer down event
        canvas.addEventListener('pointerdown', handleDown);
        canvas.addEventListener('touchstart', handleDown);

        // Function to draw the traces on the canvas
        function drawTraces() {
            // Clear the canvas
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Set the line join and line cap properties for smooth strokes
            context.lineJoin = "round";
            context.lineCap = "round";

            // Draw each trace on the canvas
            for (let i = 0; i < traces.length; i++) {
                const trace = traces[i];

                // Set the color and opacity for the trace
                context.strokeStyle = `rgba(${trace.color}, ${trace.opacity})`;
                context.lineWidth = 4;

                // Draw the trace
                context.beginPath();
                context.moveTo(trace.points[0].x, trace.points[0].y);

                for (let j = 1; j < trace.points.length; j++) {
                    context.lineTo(trace.points[j].x, trace.points[j].y);
                }

                context.stroke();
            }
        }

        // Function to fade out the traces
        function fadeOutTraces() {
            // Reduce the opacity of each trace
            for (let i = 0; i < traces.length; i++) {
                const trace = traces[i];
                trace.opacity -= fadeoutVelocity;

                // Remove the trace if the opacity is less than or equal to 0
                if (trace.opacity <= -0.2) {
                    traces.splice(i, 1);
                    i--;
                    // push the traces to facebase realtime database
                    set(ref(realtimedb, `canvas/${tableId}/traces`), traces);
                }
            }
        }

        // Function to animate the canvas
        function animateCanvas() {
            drawTraces();
            fadeOutTraces();
            requestAnimationFrame(animateCanvas);
        }

        animateCanvas()

    }, [tableId]);

    return <canvas className="overlay-content" ref={canvasRef} />;
};
export default Canvas;