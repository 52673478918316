import React from "react";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

const VenueLogoComponent = ({ position, venue }) => {
  const [imageURL, setImageURL] = React.useState("");

  React.useEffect(() => {
    const getVenueLogoURL = async () => {
      // get a image file from firebase storage path
      if (venue) {
        try {
          const storage = getStorage();
          const storageRef = ref(storage, `venues/${venue}/logo.png`);
          const url = await getDownloadURL(storageRef);
          setImageURL(url);
        }
        catch (error) {
          console.log("Error getting venue logo", error);
        }
      }
    }

    getVenueLogoURL();
  }, [venue]);
    
  return (
    <div className="venuelogo-component" style={{ position: 'absolute', top: position.top, left: position.left, width: position.width}}>
        { imageURL && <img alt="venue logo" src={imageURL}></img> }
    </div>
  );
};

export default VenueLogoComponent;

